import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenCart } from '../../store/slice';
import { useTranslation } from '../../utils/LocaleProvider';
import { useMemo } from 'react'
// import gsapTimeline, { defaultScrollTrigger } from '../gsapTimeline';

import styles from './Header.module.scss';

function Header(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const router = useRouter();
  const cart = useSelector(state => state.cart);

  const cartData = useMemo(() => {
    if (!cart) {
      return []
    }

    return Object.values(cart.cart);
  }, [cart]);

  const itemsCount = cartData.map(el => el.quantity).reduce((total, val) => total + val, 0)

  const handleBasket = () => {
    dispatch(setOpenCart());
  }

  if (router.asPath === '/checkout'){
    return (
      <></>
    )
  }

  return (
    <header className={classNames(styles.Header, props.className)}>
      {/* <Link href={router.asPath} locale={router.locale === 'ru' ? 'en' : 'ru'}>
        <button className={styles.lang}>
          {
            router.locale === 'ru' ? <>
              <span className={styles.active}>RU</span>
              &nbsp;/&nbsp;
              <span>EN</span>
            </> : <>
              <span>RU</span>
              &nbsp;/&nbsp;
              <span className={styles.active}>EN</span>
            </>
          }
        </button>
      </Link> */}
      <button onClick={handleBasket} className={styles.basket}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.6139 15.3317L13.6403 4.48908C13.6137 4.19298 13.3656 3.96617 13.0683 3.96617H11.0605V3.06064C11.0605 1.373 9.6876 0 8.00004 0C6.31256 0 4.93979 1.373 4.93979 3.06064V3.96617H2.93063C2.63338 3.96617 2.38521 4.19298 2.35864 4.48908L1.3812 15.3743C1.36681 15.535 1.42056 15.6942 1.52937 15.8132C1.63818 15.9322 1.79201 15.9999 1.95319 15.9999H14.0458C14.0463 15.9999 14.0469 15.9999 14.0474 15.9999C14.3646 15.9999 14.6216 15.7428 14.6216 15.4256C14.6216 15.3937 14.619 15.3622 14.6139 15.3317ZM6.08836 3.06064C6.08836 2.00633 6.94596 1.14857 8.00012 1.14857C9.05436 1.14857 9.91203 2.00633 9.91203 3.06064V3.96617H6.08836V3.06064ZM2.58138 14.8514L3.45568 5.11475H4.93979V6.14172C4.93979 6.45888 5.19684 6.71601 5.51407 6.71601C5.83131 6.71601 6.08836 6.45888 6.08836 6.14172V5.11475H9.91203V6.14172C9.91203 6.45888 10.1691 6.71601 10.4863 6.71601C10.8036 6.71601 11.0606 6.45888 11.0606 6.14172V5.11475H12.5433L13.4176 14.8514H2.58138Z" fill="white" />
        </svg>
        <span>корзина</span>
        {itemsCount > 0 && 
          <span data-count>{itemsCount}</span>
        }
      </button>
      <button onClick={handleBasket} className={styles.basket_mobile}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.9209 22.9975L20.4605 6.73362C20.4206 6.28947 20.0483 5.94926 19.6025 5.94926H16.5908V4.59096C16.5908 2.05951 14.5314 0 12.0001 0C9.46884 0 7.40968 2.05951 7.40968 4.59096V5.94926H4.39594C3.95006 5.94926 3.57781 6.28947 3.53796 6.73362L2.0718 23.0615C2.05021 23.3025 2.13084 23.5413 2.29405 23.7197C2.45726 23.8982 2.68801 23.9999 2.92979 23.9999H21.0687H21.071C21.5469 23.9999 21.9325 23.6142 21.9325 23.1385C21.9323 23.0906 21.9284 23.0434 21.9209 22.9975ZM9.13254 4.59096C9.13254 3.00949 10.4189 1.72286 12.0002 1.72286C13.5815 1.72286 14.8681 3.00949 14.8681 4.59096V5.94926H9.13254V4.59096ZM3.87208 22.2771L5.18352 7.67212H7.40968C8 7.67212 7.96064 7.67212 8.43649 7.67212C8.91235 7.67212 8.5 7.67212 9.13254 7.67212H14.8681H15C15.5 7.67212 15.0241 7.67212 15.5 7.67212C15.9759 7.67212 15.9556 7.67212 16.3464 7.67212H16.5909H18.815L20.1264 22.2771H3.87208Z" fill="currentColor" />
        </svg>
        {itemsCount > 0 && 
          <span>{itemsCount}</span>
        }
      </button>
    </header>
  )
}

export default React.memo(Header)

