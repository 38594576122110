import classNames from 'classnames';
import React, { useState } from 'react'
import styles from './Rotate.module.scss';
import { useRouter } from 'next/router';

function Rotate() {
  const { locale } = useRouter();
  const [isHide, setIsHide] = useState(false);

  function hide() {
    setIsHide(true);
  }

  return (
    <div className={classNames(styles.Rotate, { [styles.hide]: isHide })}>
      <span>
        {locale === 'ru' ? <>Для корректорого <br /> отображения сайта <br /> переверните экран</> : <> flip the screen  <br /> to correct visual</>}

      </span>
      <svg onClick={hide} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.71691 18.6567L18.3842 6.98948L18.7377 7.34303L7.07046 19.0103L6.71691 18.6567Z" stroke="black" strokeWidth="1.5" />
        <path d="M18.3828 19.0106L6.71555 7.34337L7.0691 6.98981L18.7364 18.6571L18.3828 19.0106Z" stroke="black" strokeWidth="1.5" />
      </svg>

      <svg className={styles.arrow} width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.41421 7.34655C8.63316 8.21782 7.36684 8.21782 6.58579 7.34654L6.99382e-07 -9.21927e-07L16 4.76837e-07L9.41421 7.34655Z" fill="white" />
      </svg>

    </div>
  )
}

export default React.memo(Rotate);