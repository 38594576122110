import Logo from "../Logo/Logo";
import styles from './PagePreloader.module.scss'


export default function PagePreloader() {
    return (
        <div className={styles.PagePreloader}>
            <Logo loading={true} />
        </div>
    )
}