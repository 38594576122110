import React from "react";

const ProgressBar = ({ total }) => {
    const progress = total / 50 

    const container = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
        marginTop: 0,
        marginBottom: 20,
    }

    const bar = {
        position: 'relative',
        width: '80%',
        maxWidth: 500,
        height: 8,
        backgroundColor: '#a6a7a6',
        opacity: .5,
        borderRadius: 10,
    }

    const filler = {
        position: 'absolute',
        height: '100%',
        width: `${progress}%`,
        maxWidth: '100%',
        backgroundColor: '#a31123',
        borderRadius: 10,
        transition: 'width 300ms ease-in',
    }

    const caption = {
        fontSize: 14,
        textTransform: 'lowercase',
    }

    if (total > 0){
        return (
            <div style={container}>
                <div style={bar}>
                    <span style={filler}></span>
                </div>
                <p style={caption}>{total > 4999? 'доставим бесплатно' : 'до бесплатной доставки'}</p>
            </div>
        )
    }

    return (
        <></>
    )
}

export default ProgressBar