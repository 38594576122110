import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenMenu } from '../../store/slice';
import { gsap } from 'gsap';

import styles from './Burger.module.scss';
import Logo from '../Logo/Logo';
import { useRouter } from 'next/router';
import Header from '../Header/Header';
import HeaderStyles from '../Header/Header.module.scss';
import { useTranslation } from '../../utils/LocaleProvider';
import { useSettings } from '../../hooks/useSettings';

function Burger({ progress }) {
  const activeScreen = useSelector(state => state.activeScreen);
  const openMenu = useSelector(state => state.openMenu);
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation();

  const { settings } = useSettings();

  const handleClickMenu = useCallback((event) => {
    if (openMenu) {
      document.body.classList.remove('menu-open');
      gsap.timeline()
        .to('.' + styles.top_burger, { rotate: 0, duration: 0.3 }, 'label-2')
        .to('.' + styles.bottom_burger, { rotate: 0, duration: 0.3 }, 'label-2')
        .to('.' + styles.top_burger, { x: -5, duration: 0.3 }, 'label-1')
        .to('.' + styles.bottom_burger, { x: 5, duration: 0.3 }, 'label-1')
    } else {
      document.body.classList.add('menu-open');
      gsap.timeline()
        .to('.' + styles.top_burger, { x: 0, duration: 0.3 }, 'label-1')
        .to('.' + styles.bottom_burger, { x: 0, duration: 0.3 }, 'label-1')
        .to('.' + styles.top_burger, { rotate: 45, duration: 0.3 }, 'label-2')
        .to('.' + styles.bottom_burger, { rotate: -45, duration: 0.3 }, 'label-2')
    }

    if (typeof event === 'boolean') {
      dispatch(setOpenMenu(event));
    } else {
      dispatch(setOpenMenu());
    }

  }, [openMenu]);

  function handleMenuClick(link) {
    handleClickMenu(false);

    gsap.to('.' + HeaderStyles.Header, { position: 'absolute' });

    if (router.route !== link) {
      document.body.classList.remove('scroll');
      router.push(link);
    }
  }

  return (
    <>
      <div className={classNames(styles.Burger, { [styles.opened]: openMenu, [styles['activeScreen-' + activeScreen]]: true })}>
        <button onClick={handleClickMenu}>
          <span className={styles.top_burger}></span>
          <span className={styles.bottom_burger}></span>
          {/* <svg width="12" height="46" viewBox="0 0 12 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="bottom-burger" d="M10 46L10 0L12 -1.2567e-07L12 46L10 46Z" fill="currentColor" />
            <path className="top-burger" d="M0 46L-1.39876e-06 0L2 -1.2567e-07L2 46L0 46Z" fill="currentColor" />
          </svg> */}
        </button>
        {
          progress && (
            <span className={styles.Burger_progress}>
              <span className={styles.number}>{activeScreen}</span>
              <span className={styles.progress} style={{ '--activeScreen': activeScreen }}>/</span>
              <span className={styles.number}>6</span>
            </span>
          )
        }
      </div>

      <div className={classNames(styles.Burger_menu, { [styles.opened]: openMenu, [styles['activeScreen-' + activeScreen]]: true })}>
        <div className={styles.Burger_menu_logo_container}>
          <Logo onClick={() => handleMenuClick('/')} />
        </div>

        <Header className={styles.Burger_menu_header}></Header>

        {/* <div className="bg"></div> */}
        <div className={styles.Burger_menu_nav}>
          <a className={styles.a} onClick={() => handleMenuClick('/')}>{t('menu.main', 'Главная')}</a>
          <a className={styles.a} onClick={() => handleMenuClick('/about')}>{t('menu.about', 'о продукте')}</a>
          <a className={styles.a} onClick={() => handleMenuClick('/shop')}>{t('menu.shop', 'магазин')}</a>
          <a className={styles.a} onClick={() => handleMenuClick('/contacts')}>{t('menu.contacts', 'контакты')}</a>
          <a className={styles.a} onClick={() => handleMenuClick('/delivery')}>{t('menu.delivery_and_pay', 'Доставка и оплата')}</a>
          <a className={styles.a} onClick={() => handleMenuClick('/partner')}>партнерство</a>
        </div>

        <div className={styles.Burger_menu_socials}>
          {settings?.shop_vk && <a href={settings.shop_vk} target="_blank" rel="noreferrer">
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0ZM18.9249 23.0243C18.7523 23.2062 18.4154 23.243 18.4154 23.243H17.2998C17.2998 23.243 14.8384 23.3882 12.67 21.1728C10.3054 18.7558 8.21737 13.9603 8.21737 13.9603C8.21737 13.9603 8.09686 13.6457 8.22742 13.4943C8.37441 13.3231 8.77521 13.3123 8.77521 13.3123L11.442 13.2953C11.442 13.2953 11.6931 13.3356 11.873 13.4656C12.0218 13.5731 12.1049 13.773 12.1049 13.773C12.1049 13.773 12.5358 14.843 13.1064 15.8109C14.2212 17.701 14.7398 18.1141 15.1177 17.9116C15.6692 17.6167 15.5039 15.24 15.5039 15.24C15.5039 15.24 15.514 14.3779 15.2264 13.9934C15.0036 13.6959 14.5836 13.609 14.3983 13.5848C14.2477 13.5651 14.4942 13.2227 14.8137 13.0695C15.2939 12.8392 16.1412 12.8257 17.1427 12.8356C17.9233 12.8436 18.1479 12.8911 18.4529 12.9637C19.1606 13.1314 19.1385 13.6686 19.0908 14.8281C19.0766 15.1749 19.06 15.5773 19.06 16.0421C19.06 16.1466 19.0569 16.2579 19.0537 16.3725C19.0372 16.9665 19.0182 17.6467 19.417 17.8999C19.6215 18.029 20.1209 17.9187 21.3708 15.836C21.9633 14.8484 22.407 13.6878 22.407 13.6878C22.407 13.6878 22.5047 13.4808 22.6554 13.3921C22.8096 13.3016 23.0178 13.3294 23.0178 13.3294L25.8243 13.3123C25.8243 13.3123 26.6679 13.2129 26.804 13.5875C26.9473 13.9791 26.489 14.895 25.3423 16.3952C24.2545 17.8187 23.7252 18.3428 23.7738 18.8063C23.8093 19.145 24.1534 19.4513 24.8137 20.0526C26.1921 21.3088 26.5615 21.9697 26.6503 22.1285C26.6577 22.1417 26.6631 22.1514 26.667 22.1577C27.2851 23.1641 25.9814 23.243 25.9814 23.243L23.488 23.277C23.488 23.277 22.953 23.381 22.2482 22.906C21.8789 22.6574 21.5182 22.2514 21.1745 21.8647C20.6497 21.274 20.1649 20.7284 19.7511 20.8573C19.0564 21.0742 19.0774 22.5431 19.0774 22.5431C19.0774 22.5431 19.0828 22.8576 18.9249 23.0243Z" fill="white" />
            </svg>
          </a>}
          {settings?.shop_whatsapp && <a href={settings.shop_whatsapp} target="_blank" rel="noreferrer">
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M0 17.5C0 27.165 7.83502 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5ZM28.4828 16.8303C28.4805 22.5664 23.8142 27.2339 18.0768 27.2362H18.0725C16.3309 27.2356 14.6196 26.7986 13.0997 25.9696L7.58333 27.4167L9.05961 22.0244C8.14897 20.4462 7.6698 18.6561 7.67058 16.8221C7.67286 11.0846 12.341 6.41667 18.0767 6.41667C20.8605 6.41787 23.4732 7.50157 25.4379 9.46853C27.4025 11.4354 28.4839 14.0498 28.4828 16.8303Z" fill="white" />
              <path fillRule="evenodd" clipRule="evenodd" d="M10.082 24.944L13.3555 24.0853L13.6714 24.2727C14.9993 25.0608 16.5215 25.4777 18.0735 25.4783H18.077C22.8442 25.4783 26.7241 21.5983 26.726 16.8292C26.7269 14.5181 25.8281 12.3451 24.1952 10.7103C22.5622 9.07541 20.3906 8.17463 18.0804 8.17383C13.3096 8.17383 9.42963 12.0535 9.42774 16.8222C9.42707 18.4565 9.88433 20.0481 10.7501 21.4251L10.9558 21.7524L10.082 24.944ZM22.9634 19.0702C23.1448 19.1579 23.2672 19.217 23.3195 19.3043C23.3845 19.4127 23.3845 19.9333 23.1679 20.5408C22.9512 21.1481 21.9124 21.7025 21.4128 21.7771C20.9649 21.8441 20.398 21.872 19.7752 21.674C19.3975 21.5542 18.9132 21.3943 18.2929 21.1263C15.8553 20.0738 14.208 17.7113 13.8967 17.2648C13.8749 17.2335 13.8596 17.2116 13.8511 17.2003L13.849 17.1975C13.7114 17.0139 12.7894 15.7837 12.7894 14.5105C12.7894 13.3129 13.3778 12.6851 13.6486 12.3961C13.6671 12.3763 13.6842 12.3581 13.6995 12.3414C13.9378 12.0811 14.2195 12.016 14.3928 12.016C14.5661 12.016 14.7397 12.0176 14.8912 12.0253C14.9099 12.0262 14.9293 12.0261 14.9494 12.026C15.1009 12.0251 15.2898 12.024 15.4762 12.4716C15.5479 12.6439 15.6528 12.8992 15.7634 13.1686C15.9871 13.7133 16.2343 14.3151 16.2778 14.4022C16.3429 14.5324 16.3862 14.6841 16.2995 14.8577C16.2865 14.8838 16.2745 14.9083 16.263 14.9318C16.1979 15.0647 16.15 15.1625 16.0395 15.2915C15.9961 15.3422 15.9512 15.3969 15.9063 15.4516C15.8168 15.5605 15.7274 15.6694 15.6495 15.747C15.5193 15.8767 15.3838 16.0173 15.5355 16.2777C15.6872 16.538 16.209 17.3894 16.9819 18.0789C17.8129 18.82 18.535 19.1332 18.9011 19.292C18.9725 19.323 19.0304 19.3481 19.0729 19.3694C19.3328 19.4996 19.4845 19.4778 19.6362 19.3043C19.7879 19.1308 20.2862 18.5451 20.4595 18.2848C20.6328 18.0246 20.8062 18.0679 21.0445 18.1547C21.2829 18.2415 22.5612 18.8705 22.8212 19.0006C22.872 19.026 22.9194 19.049 22.9634 19.0702Z" fill="white" />
            </svg>
          </a>}
          {settings?.shop_tme && <a href={settings.shop_tme}  target="_blank" rel="noreferrer">
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M0 17.5C0 27.165 7.83502 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5ZM14.2917 25.5208L14.5894 21.06L14.5892 21.0598L22.704 13.7368C23.0601 13.4207 22.6262 13.2666 22.1535 13.5533L12.1386 19.8715L7.81279 18.5213C6.87859 18.2353 6.87189 17.5933 8.02252 17.1318L24.8794 10.6319C25.6493 10.2823 26.3924 10.8168 26.0985 11.9952L23.2277 25.5231C23.0273 26.4844 22.4464 26.7143 21.6417 26.2703L17.2686 23.0394L15.1667 25.0833C15.16 25.0898 15.1534 25.0962 15.1469 25.1026C14.9118 25.3315 14.7173 25.5208 14.2917 25.5208Z" fill="white" />
            </svg>
          </a>}
        </div>

        <div className={styles.Burger_menu_footer}>
          <a className={styles.Burger_menu_footer_policy} onClick={() => handleMenuClick('/policy')}>{t('menu.policy', 'Пользовательское соглашение')}</a>
          {settings?.shop_phone && <a className={styles.Burger_menu_footer_phone} href={"tel:" + settings.shop_phone}>{settings.shop_phone}</a>}
        </div>
      </div>
    </>
  )
}

export default React.memo(Burger)
