const myTarget = (productid, pagetype, list, totalvalue) => {
    var _tmr = window._tmr || (window._tmr = []);
    _tmr.push({
        type: 'itemView',
        productid,
        pagetype,
        list,
        totalvalue,
    });
}

export default myTarget