import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import useSWRImmutable from "swr/immutable";

axios.defaults.withCredentials = true;

const fetcher = (...args) => fetch(...args, { credentials: 'include' }).then((res) => res.json())

export const getCartApi = function (prefix, cartmethod) {
    return createAsyncThunk(
      'cart/' + prefix,
      async (body, thunkAPI) => {
        await axios.get(process.env.NEXT_PUBLIC_BACKEND + "/sanctum/csrf-cookie");
        const { data } = await axios.post(process.env.NEXT_PUBLIC_BACKEND_API + cartmethod, body);
        return data;
      }
    );
}

export const addToCartApi = getCartApi('addToCartApi', 'add-cart');

export const removeCartApi = getCartApi('removeCartApi', 'remove-cart');

export const updateCartApi = getCartApi('updateCartApi', 'update-cart');

export const checkPromocode = getCartApi('checkPromocode', 'promocode');

export const sendForm = getCartApi('sendForm', 'checkout');

const initialState = {
  loaded: false,
  firstAnimation: false,
  activeScreen: 1,
  isChangeScreen: false,
  openMenu: false,
  openCart: false,
  deliveryPrice: 0,
  progressSeq: 0,
  cartLoading: false,
  pageLoading: false,
  popUpOpen: false,
  manualOpen: false,
  promocode: {
    type: 'percent',
    discount: 0
  },
  cart: {
    cart: [],
    count: 0,
    total_sum: 0,
  },
}

export const appSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setLoaded(state, action) {
      state.loaded = action.payload ?? true;
    },

    setFirstAnimation(state, action) {
      state.firstAnimation = action.payload ?? true;
    },

    setActiveScreen(state, action) {
      state.activeScreen = action.payload;
    },

    toggleChangeScreen(state) {
      state.isChangeScreen = !state.isChangeScreen;
    },

    setOpenMenu(state, action) {
      state.openMenu = action.payload ?? !state.openMenu
    },

    setOpenCart(state, action) {
      state.openCart = action.payload ?? !state.openCart;

      if (state.openCart) {
        document.body.classList.add('menu-open');
      } else {
        document.body.classList.remove('menu-open');
      }
    },

    setCart(state, action) {
      state.cart = action.payload;
    },

    setStart(state) {
      state.loaded = false;
      state.activeScreen = 1;
      state.firstAnimation = false;
      state.isChangeScreen = false;
    },

    setProgressSeq(state, action) {
      state.progressSeq = action.payload;
    },

    setCartLoading(state, action){
      state.cartLoading = action.payload ?? !state.cartLoading;
    },

    setPageLoading(state, action){
      state.pageLoading = action.payload ?? !state.pageLoading;

      if (state.pageLoading) {
        document.body.classList.add('menu-open');
      } else {
        document.body.classList.remove('menu-open');
      }
    },

    setPopUpOpen(state, action) {
      state.popUpOpen = action.payload ?? !state.popUpOpen;

      if (state.popUpOpen) {
        document.body.classList.add('menu-open');
      } else {
        document.body.classList.remove('menu-open');
      }
    },

    setManualOpen(state, action) {
      state.manualOpen = action.payload ?? !state.manualOpen;

      if (state.manualOpen) {
        document.body.classList.add('menu-open');
      } else {
        document.body.classList.remove('menu-open');
      }      
    }

  },

  extraReducers: {
    [checkPromocode.fulfilled](state, action) {
      state.cart = action.payload;
    },
    [updateCartApi.fulfilled](state, action) {
        state.cart = action.payload;
    },
    [removeCartApi.fulfilled](state, action) {
        state.cart = action.payload;
    },
    [addToCartApi.fulfilled](state, action) {
        state.cart = action.payload;
    },
  }
})

// Action creators are generated for each case reducer function
export const {
  setProgressSeq,
  setLoaded,
  setFirstAnimation,
  setActiveScreen,
  toggleChangeScreen,
  setOpenMenu,
  setOpenCart,
  setCart,
  setStart,
  setCartLoading,
  setPageLoading,
  setPopUpOpen,
  setManualOpen
} = appSlice.actions

export default appSlice.reducer
