import React from 'react';
import styles from './CartItem.module.scss';
import classNames from "classnames";
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { removeCart, removeCartApi, updateCartApi, updateCart, setOpenCart, setCartLoading } from '../../store/slice';
import { useRef } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';


function useDebouncedFunction(func, delay) {
  const ref = useRef(null);

  return (...args) => {
    clearTimeout(ref.current);
    ref.current = setTimeout(() => func(...args), delay);
  };
}

function CartItem({ item = {}, className }) {
  const dispatch = useDispatch();
  const { locale, push } = useRouter();

  const handleRemove = useCallback(() => {
    dispatch(removeCartApi({ id: item.id }));
    dispatch(setCartLoading())
  }, [item.id, dispatch]);

  const updateCartApiDown = useCallback((quantity) => {
    dispatch(updateCartApi({ id: item.id, quantity: quantity - 1 }))
  }, [item.id, dispatch]);

  const updateCartApiUp = useCallback((quantity) => {
    dispatch(updateCartApi({ id: item.id, quantity: quantity + 1 }))
  }, [item.id, dispatch]);


  const debounceDown = useDebouncedFunction(updateCartApiDown, 300);
  const debounceUp = useDebouncedFunction(updateCartApiUp, 300);

  const handleDown = useCallback(() => {
    debounceDown(item.quantity);
    dispatch(setCartLoading())
  }, [item.id, item.quantity, dispatch]);

  const handleUp = useCallback(() => {
    debounceUp(item.quantity);
    dispatch(setCartLoading())
  }, [item.id, item.quantity, dispatch]);

  function handleLink() {
    push('/shop/' + item.slug);
    dispatch(setOpenCart(false));
  }

  return (
    <div className={classNames(styles.Cart_item, className)}>
      <a onClick={handleLink} className={styles.Cart_item_image}>
        {/* <img src={process.env.NEXT_PUBLIC_BACKEND + '/' + item.image} alt="" /> */}
        <Image src={item.image} alt="" layout='fill' />
      </a>
      <div className={styles.Cart_item_container}>
        <div className={styles.Cart_item_row}>
          <a onClick={handleLink} className={styles.Cart_item_title}>{item.name.ru}</a>
          {(() => {
            if (!item.isgift) {
              return (
              <button onClick={handleRemove} type="button" className={styles.Cart_item_drop}>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.99035 18.3848L18.6576 6.7175L19.0112 7.07106L7.3439 18.7383L6.99035 18.3848Z" stroke="black" strokeWidth="1.5" />
                  <path d="M18.6562 18.7387L6.98899 7.07139L7.34254 6.71784L19.0098 18.3851L18.6562 18.7387Z" stroke="black" strokeWidth="1.5" />
                </svg>
              </button>
              )
            }
          })()}
        </div>

                {(() => {
                  if (item.isgift) {
                    return (
                      <div className={styles.Cart_item_row}>
                        <div className={styles.Cart_item_gift}>Подарок</div>
                      </div>
                    )
                  } else {
                    return (
                      <div className={styles.Cart_item_row}>
                        <div className={styles.Cart_item_total}>
                          <button type="button" onClick={handleDown} className={styles.Cart_item_total_button}>-</button>
                          <div className={styles.Cart_item_total_count}>{item.quantity}</div>
                          <button type="button" onClick={handleUp} className={styles.Cart_item_total_button}>+</button>
                        </div>
                        <div>{item.price} ₽</div>
                      </div>
                    )
                  }
                })()}

      </div>
    </div>
  );
}

export default CartItem;
