import React, { createContext, useContext } from "react";
import { useCallback } from "react";

const LocaleContext = createContext(null);

const fetcher = (...args) => fetch(...args).then((res) => res.json());


global.lang = {};

global.getPhpLang = (data) => {
  let text = ``;

  Object.keys(data).forEach(key => {
    if (typeof data[key] === 'string') {
      text += `"${key}" => "${data[key]}",`;
    } else {
      if (data[key]) {
        text += `"${key}" => [${global.getPhpLang(data[key])}],`;
      }
    }
  });

  return text;
}

export const LocaleProvider = ({ children, locale, data }) => {

  //overrides locale making it imposible to change it on the site
  locale = 'ru'

  function breakLine(text) {
    var regex = /(<br \/>)/g;
    return text.split(regex).map(function (line, index) {
      return line.match(regex) ? <br key={"key_" + index} /> : line;
    });
  }


  const t = useCallback((key, defaultValue) => {
    const getData = () => {
      if (key.indexOf('.') > -1) {
        const [k1, k2] = key.split('.');
        global.lang[k1] = global.lang[k1] || {};
        global.lang[k1][k2] = defaultValue;
      } else {
        global.lang[key] = defaultValue;
      }

      if (data) {
        if (key.indexOf('.') > -1) {
          try {
            const [k1, k2] = key.split('.');
            return data[locale][k1][k2] ?? defaultValue ?? key;
          } catch (e) {
            return defaultValue ?? key;
          }
        } else {
          return data[locale][key] ?? defaultValue ?? key;
        }
      } else {
        return defaultValue ?? key;
      }
    }

    return breakLine(getData()
      .replace(/[\r\n]/, '<br />'));

  }, [data, locale]);

  const settings = {
    locale,
    data: data ?? {},
    t
  }

  return (
    <LocaleContext.Provider value={settings}>
      {children}
    </LocaleContext.Provider>
  );
};

export function useTranslation() {
  return useContext(LocaleContext);
}

