import banner from '../../public/assets/about-lips-flag.webp'

import styles from './PopUp.module.scss'

import Image from 'next/image'
import { useRouter } from 'next/router';

import { useDispatch, useSelector } from "react-redux";
import { setPopUpOpen } from '../../store/slice';

import { useState, useEffect } from 'react';

import Cookies from 'js-cookie'


export default function PopUp () {

    const router = useRouter()
    const dispatch = useDispatch()
    const popUpOpen = useSelector(state => state.popUpOpen)

    const [input, setInput] = useState('')
    const [mounted, setMounted] = useState(false)
    const [emailSent, setEmailSent] = useState(false)

    const notIndex = router.asPath !== '/'
    const notCheckout = router.asPath !== '/checkout'

    const TIME_OUT = 35000

    const handleClose = () => {
        dispatch(setPopUpOpen(false))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setInput('')
        setEmailSent(true)
        Cookies.set('pop_up_email_sent', 'true', {expires: 365})
        dispatch(setPopUpOpen(false))
    }

    const handleChange = (e) => {
        setInput(e.target.value)
    }

    const hasEmailCookie = () => {
        return Cookies.get('pop_up_email_sent') === undefined ? false : true
    }

    useEffect(() => {

        let timer

        if (hasEmailCookie()) {
            setEmailSent(true)

            return
        }

        if (mounted && (notIndex && notCheckout) && !emailSent) {

            if (Cookies.get('pop_up') === undefined){

                timer = setTimeout(() => {
                dispatch(setPopUpOpen())
                Cookies.set('pop_up', '1', {expires: 1})
                }, TIME_OUT)
            }
    
            if (Cookies.get('pop_up') === '1'){

                timer = setTimeout(() => {
                    dispatch(setPopUpOpen())
                    Cookies.remove('pop_up')
                    Cookies.set('pop_up', '2', {expires: 1})
                }, TIME_OUT)
            }
        }

        if (!mounted){
            setMounted(true)
        }

        return () => {
            clearTimeout(timer)
        }

    }, [router.asPath])


    if (!popUpOpen || emailSent ) {
        return <></>
    }

    return (
        <div className={styles.PopUp_wrapper}>
            <div className={styles.PopUp_content}>
                <button onClick={handleClose} className={styles.PopUp_content_closeBtn}></button>
                <div className={styles.PopUp_content_imageContainer}>
                    <Image src={banner} layout='fill'/>
                </div>
                <div className={styles.PopUp_content_textContainer}>
                    <p>тыры-пыры, трали-вали, вроде скидку мы вам дали, иль не дали, решайте сами</p>
                    <form onSubmit={handleSubmit}>
                        <input onChange={handleChange} type="email" placeholder="напиши e-mail" value={input} maxLength={65} />
                        <button>отправить</button>
                    </form>
                </div>
            </div>
        </div>
    )
}