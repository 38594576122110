import { Provider } from 'react-redux'
import { store } from '../store/app'
import { setPopUpOpen } from '../store/slice';

import '../styles/globals.scss';

import '../utils/flag';
import resize from '../utils/resize';
import * as ga from '../utils/ga'
import Script from 'next/script';
import Head from 'next/head';
import Header from '../components/Header/Header';
import Burger from '../components/Burger/Burger';
import Cart from '../components/Cart/Cart';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { setStart } from '../store/slice';
import { useDispatch, useSelector } from 'react-redux';

import Layout from '../components/layout';
import { ErrorBoundary } from '../components/Error';
import { appWithTranslation } from 'next-i18next';
import { LocaleProvider } from '../utils/LocaleProvider';
import Rotate from '../components/Rotate/Rotate';
import PagePreloader from '../components/PagePreloader/PagePreloader';
import PopUp from '../components/PopUp/PopUp';

function App({ Component, pageProps }) {

  const { locale } = useRouter();
  const router = useRouter()
  const [pixel, setPixel] = useState(null)

  useEffect(() => {
    window.vkAsyncInit = function() {
      window.pixel = new VK.Pixel(process.env.NEXT_PUBLIC_VK_RETARGETING)
    }
  }, [])

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <Provider store={store}>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi" />
      </Head>

      <ErrorBoundary>
        <LocaleProvider data={pageProps.lang} locale={locale} >
          <Layout>
            <Header />
            <Burger progress={pageProps.progress} />
            <Cart />
            <Rotate />
            {/* <PopUp /> */}

            <Main>
              <Component  {...pageProps} />
            </Main>
          </Layout>
        </LocaleProvider>

        <Script src="/curtains.js" strategy="beforeInteractive"></Script>

        <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`} />
        <Script strategy="lazyOnload">
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
              });
          `}
        </Script>

        <Script>
          {`
              !function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://vk.com/js/api/openapi.js?169',t.onload=function(){VK.Retargeting.Init("${process.env.NEXT_PUBLIC_VK_RETARGETING}"),VK.Retargeting.Hit()},document.head.appendChild(t)}();
          `}
        </Script>        
        <Script strategy='lazyOnLoad' src="//vk.com/js/api/openapi.js?159" async/>

        <Script>
          {`
              var _tmr = window._tmr || (window._tmr = []);
              _tmr.push({id: "3266367", type: "pageView", start: (new Date()).getTime(), pid: "USER_ID"});
              (function (d, w, id) {
                if (d.getElementById(id)) return;
                var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
                ts.src = "https://top-fwz1.mail.ru/js/code.js";
                var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
                if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
              })(document, window, "tmr-code");
              
          `}
        </Script>

        <Script>
          {`
              (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
              m[i].l=1*new Date();
              for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
              k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
              (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
           
              ym(90320940, "init", {
                   clickmap:true,
                   trackLinks:true,
                   accurateTrackBounce:true,
                   webvisor:true,
                   ecommerce:"dataLayer"
              });
           
          `}
        </Script>
      </ErrorBoundary>
    </Provider >
  )
}

function Main({ children }) {
  const router = useRouter();
  const dispatch = useDispatch();

  const pageLoading = useSelector(state => state.pageLoading)

  useEffect(() => {
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    }
  })
  
  useEffect(() => {
    let timer;
    if (typeof document !== "undefined") {
      document.body.classList.add('loaded');
      document.body.querySelectorAll('.bg').forEach(bg => bg.classList.add('loaded'));

      timer = setTimeout(() => {
        document.body.querySelectorAll('.bg').forEach(bg => bg.classList.add('show'));
      }, 1000);
    }

    return () => {
      if (timer) {
        return timer;
      }
    }
  }, []);

  useEffect(() => {
    const handleStart = (event) => {
      if (!((router.asPath === '/' && event === '/') || (router.asPath === '/' && event === '/en'))) {
        dispatch(setStart());
      }
    }

    router.events.on('routeChangeStart', handleStart);

    return () => {
      router.events.off('routeChangeStart', handleStart);
    }
  }, [router])



  return (
    <>
      {pageLoading && <PagePreloader />}
      {children}
    </>
  );
}
// App.getInitialProps = async () => {
//   const data = await fetcher('uuid');

//   console.log(data);

//   return {}
// }

// Resolution order
//
// On the server:
// 1. app.getInitialProps
// 2. page.getInitialProps
// 3. document.getInitialProps
// 4. app.render
// 5. page.render
// 6. document.render
//
// On the server with error:
// 1. document.getInitialProps
// 2. app.render
// 3. page.render
// 4. document.render
//
// On the client
// 1. app.getInitialProps
// 2. page.getInitialProps
// 3. app.render
// 4. page.render


export default appWithTranslation(App);
