import React from 'react';
import styles from './styles.module.scss';
import classNames from "classnames";

function Button({ title, border = true, backgound = true, color = '#fff', className, onClick, type }) {
  return (
    <button type={type} onClick={onClick} style={{ '--color': color }} className={classNames(styles.button, { [styles.button_bordered]: border }, className)}>
      <div className={styles.button_backgound}>
        {(border || backgound) && <svg width="255" height="119" viewBox="0 0 255 119" xmlns="http://www.w3.org/2000/svg">
          {backgound && <ellipse cx="127.88" cy="59.4654" rx="120.613" ry="54.5017" />}
          {border && <path d="M254.202 59.6251C254.202 75.6067 240.238 90.2319 217.294 100.891C194.392 111.531 162.717 118.125 127.702 118.125C92.6883 118.125 61.0133 111.531 38.1106 100.891C15.1664 90.2319 1.20245 75.6067 1.20245 59.6251C1.20245 43.6436 15.1664 29.0184 38.1106 18.3593C61.0133 7.71942 92.6883 1.12512 127.702 1.12512C162.717 1.12512 194.392 7.71942 217.294 18.3593C240.238 29.0184 254.202 43.6436 254.202 59.6251Z" />}
        </svg>}
      </div>
      <div className={styles.button_title}>{title}</div>
    </button>
  );
}

export default Button;
