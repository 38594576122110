function resize() {
  let vw = window.innerWidth * 0.01;
  let exp = window.innerWidth / 1920;
  let vh = window.innerHeight * 0.01;
  // let size = window.innerWidth * 16 / 1920;

  exp = Math.ceil(Math.exp(exp - 1) * 100) / 100;
  let size = Math.exp(window.innerWidth / 1920) / 2.7 * 16;
  // exp = Math.ceil(Math.exp(exp - 1) * 100) / 100;

  if (window.innerWidth > 1920) {
    size = 16;
  }

  document.documentElement.style.setProperty('--vw', `${vw}px`);
  document.documentElement.style.setProperty('--exp', `${exp}`);
  document.documentElement.style.setProperty('font-size', `${size}px`);
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  document.getElementById('after_header') || document.documentElement.style.setProperty('overflow', `visible`);
}



if (typeof window !== "undefined") {
  resize();
}

export default resize;