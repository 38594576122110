import useSWRImmutable from "swr";

export const useSettings = () => {
  const address = process.env.NEXT_PUBLIC_BACKEND_API + 'settings';
  const fetcher = (...args) => fetch(...args).then((res) => res.json());
  const { data, error } = useSWRImmutable(address, fetcher);

  return {
    settings: data,
    isLoading: !error && !data,
    isError: error
  }
}
