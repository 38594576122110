import React from 'react'
import styles from './Logo.module.scss';
import { useRouter } from 'next/router';
import classNames from 'classnames';

function Logo({ className, onClick, loading }) {
  const router = useRouter();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      router.push('/');
    }
  }

  return (
    <>
      {/* <Link href="/"> */}
      <span onClick={handleClick}>
        <svg preserveAspectRatio='none' className={classNames(styles.Logo, { [styles.Logo_cartLoading] : loading === true}, { [className]: className })} width="1097" height="441" viewBox="0 0 1097 441" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M217.46 103.139C217.46 91.2884 215.088 80.1489 210.107 69.9574C205.363 59.7659 198.722 50.9965 190.183 43.4121C181.882 36.0648 171.92 30.1395 160.298 25.6363C148.676 21.1331 136.342 19 122.823 19C109.066 19 96.2581 21.3701 84.636 25.8733C73.0139 30.3765 62.815 36.3018 54.2763 43.8862C45.7376 51.2335 39.0964 60.0029 34.1156 70.1944C29.3719 80.3859 27 91.5254 27 103.376V340.624C27 352.475 29.3719 363.614 34.1156 373.806C38.8593 383.997 45.5005 393.003 54.2763 400.351C62.815 407.698 73.0139 413.86 84.636 418.364C96.2581 422.867 109.066 425 122.823 425.237C136.105 425.237 148.676 423.104 160.298 418.601C171.92 414.097 181.882 408.172 190.183 400.825C198.485 393.478 205.126 384.471 210.107 374.28C214.851 364.088 217.46 352.949 217.46 341.098V103.139ZM156.74 382.101C155.792 387.315 154.131 392.055 151.522 396.559C148.913 401.062 145.355 404.617 140.612 407.698C135.868 410.779 130.176 412.201 123.06 412.201C115.707 412.201 109.54 410.542 105.034 407.461C100.29 404.38 96.7325 400.588 93.8862 396.085C91.04 391.581 89.1425 386.841 87.9566 381.627C86.7707 376.413 86.2963 371.672 86.2963 367.643V76.3567C86.2963 72.3275 86.7707 67.5873 87.9566 62.373C89.1425 57.1588 91.04 52.4186 93.8862 47.9154C96.7325 43.4121 100.29 39.62 105.034 36.5388C109.778 33.4577 115.707 31.7986 123.06 31.7986C130.176 31.7986 136.105 33.2207 140.612 36.3018C145.355 39.383 148.913 42.9381 151.522 47.4413C154.131 51.9445 155.792 56.6848 156.74 61.899C157.689 67.1132 158.163 71.8535 158.163 76.3567V367.643C158.163 372.147 157.689 377.124 156.74 382.101Z" fill="white" />
          <path d="M381.829 190.596L451.561 25.3991H433.061L424.048 47.2041L373.527 168.08L320.161 25.1621H260.864L342.219 242.738L267.743 421.208H285.532L350.995 265.254L409.105 421.445L468.164 421.682L381.829 190.596Z" fill="white" />
          <path d="M777.928 399.403L855.251 26.3472H836.987L765.12 375.464C762.985 363.851 703.926 26.1102 703.926 26.1102H643.918C643.918 26.1102 662.656 131.817 667.163 157.888L622.572 375.464C620.437 363.851 618.065 350.815 615.456 335.884C612.847 320.478 561.378 25.1621 561.378 25.1621H501.37L571.814 421.919L639.412 422.156C639.412 422.156 635.38 399.403 635.38 399.166L675.464 203.868C678.785 222.592 682.105 241.316 685.663 260.277C689.221 279.238 714.125 422.156 714.125 422.156L781.723 422.393C781.723 422.156 777.928 399.403 777.928 399.403Z" fill="white" />
          <path d="M1081.05 408.647H1018.67C1018.67 408.647 1018.67 408.647 1018.44 408.647H964.831L965.306 177.087V175.428L1040.02 175.665V157.889L965.306 157.652L965.543 40.3313H1075.83L1077.02 26.5847L905.535 26.3477V422.393L1081.76 422.63L1081.05 408.647Z" fill="white" />
        </svg>

      </span>
      {/* </Link> */}
    </>
  )
}

export default React.memo(Logo)
