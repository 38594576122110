import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSWRImmutable from 'swr/immutable'
import { setCart } from '../store/slice';

import axios from "axios";

axios.defaults.withCredentials = true;

const fetcher = (...args) => fetch(...args, { credentials: 'include' }).then((res) => res.json())


export default function Layout({ children }) {
  const { data, error } = useSWRImmutable(process.env.NEXT_PUBLIC_BACKEND_API + 'uuid', fetcher);
  const cart = useSWRImmutable(process.env.NEXT_PUBLIC_BACKEND_API + 'cart', fetcher);
  const dispatch = useDispatch();


  useEffect(() => {
    if (cart.data) {
      dispatch(setCart(cart.data));
    }
  }, [cart.data]);


  return children;
}