import * as Sentry from "@sentry/nextjs";
import { Component } from "react";

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // we can log error or errorInfo to Sentry here.
        // errorInfo will contain the details 
        // and stack trace which is better for debugging

        Sentry.captureException(errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}