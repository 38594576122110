import React, { useEffect, useMemo } from 'react';
import CartItem from "../CartItem/CartItem";
import ProgressBar from '../ProgressBar/ProgressBar';
import styles from './Cart.module.scss';
import Button from "../Button/Button";
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { setOpenCart, setOpenMenu, setCartLoading, setPageLoading } from '../../store/slice';
import { useRouter } from 'next/router'
import { useTranslation } from '../../utils/LocaleProvider';
import * as ga from '../../utils/ga'
import myTarget from '../../utils/myTarget';
import Logo from '../Logo/Logo';

function Cart(props) {
  const [total, setTotal] = React.useState(0)
  const openCart = useSelector(state => state.openCart);
  const cart = useSelector(state => state.cart);
  const dispatch = useDispatch();
  const router = useRouter();
  const { t, locale } = useTranslation();
  const cartLoading = useSelector(state => state.cartLoading)


  const handleBasket = () => {
    dispatch(setOpenCart());
  }

  function goToCheckout() {
    handleBasket();
    document.body.classList.remove('menu-open');
    dispatch(setOpenMenu(false));
    dispatch(setPageLoading())
    beginCheckoutGoogleAnalytics()
    router.push('/checkout');
  }

  function goToShop() {
    handleBasket();
    document.body.classList.remove('menu-open');
    dispatch(setOpenMenu(false));
    router.push('/shop');
  }

  const cartData = useMemo(() => {
    if (!cart) {
      return []
    }

    return Object.values(cart.cart);
  }, [cart]);

  useEffect(() => {
    if (cart) {
      setTotal(Object.values(cart.cart).reduce((number, item) => number += item.price * item.quantity, 0))
    }
  }, [cart]);

  useEffect(() => {
    if (openCart && cartData.length > 0){
      const cartIds = cartData.map(el => {
        return el.id
      })
      myTarget(cartIds, 'cart', 1, total)
      openCartYandexMetrika()
    }
  }, [openCart])

  useEffect(() => {
    dispatch(setCartLoading())
    if (cartData.length > 0){
      dispatch(setCartLoading(false))
    }
  }, [cartData])

  useEffect(() => {
    if (cartData.length > 0){
      dispatch(setCartLoading(false))
    }
  }, [])

  const beginCheckoutGoogleAnalytics = () => {
    ga.event({
      action: 'begin_checkout',
      params: {
        currency: 'RUB',
        value: total,
        items: [
          ...cartData,
        ]
      }
    })
  }

  const openCartYandexMetrika = () => {
    ym(90320940,'reachGoal','clickcart')
  }

  return (
    <div className={classNames(styles.Cart, { [styles.active]: openCart })}>
      <div onClick={handleBasket} className={styles.Cart_wrapper} />
      <div className={styles.Cart_container}>

        {cartLoading && 
          <span className={styles.Cart_container_blur}>
            <Logo loading={true} />
          </span>
        }

        <ProgressBar total={total} />
        <div className={styles.Cart_header}>
          <div className={styles.Cart_title}>{cartData.length > 0 && "корзина"}</div>
          <button onClick={handleBasket} type="button" className={styles.Cart_close}>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 32.5269L32.5269 -5.8651e-05L33.9411 1.41416L1.41421 33.9411L0 32.5269Z" fill="black" />
              <path d="M32.5566 33.9702L0.0297265 1.4433L1.44394 0.0290888L33.9709 32.556L32.5566 33.9702Z" fill="black" />
            </svg>
          </button>
        </div>
        <div className={styles.Cart_list}>
          {cartData.map((item) =>
            <CartItem key={item.id} item={item} className={styles.Cart_item} />
          )}
        </div>
        {cartData.length > 0 ? <>
          <div className={styles.Cart_total}>
            {t('total', 'Всего')} <div className={styles.Cart_total_count}>{total} ₽</div>
          </div>
          <div className={styles.Cart_footer}>
            <Button onClick={goToCheckout} title={t('checkout_button', 'ОФОРМИТЬ')} color="#A31123" />
            <a onClick={goToShop}>{locale === 'ru' ? 'вернуться к покупкам' : 'back to shopping'}</a>
            <div className={styles.Cart_description}>{t('cart_footer', 'Налоги, стоимость доставки и скидки рассчитываются при оформлении заказа.')}</div>
          </div>
        </> : (
          <div className={styles.Cart_empty}>
            <div className='h4'>корзина</div>
            <div className='h5'>{locale === 'ru' ? <>Сейчас ваша корзина пуста. <br /> Выберите что&#8209;нибудь&nbsp;из&nbsp;<a onClick={goToShop}>нашего каталога</a></> : 'Your shopping cart is empty'}</div>
          </div>
        )}

      </div>
    </div >
  );
}

export default Cart;
